const DATE_REGEX = /\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}-\d{2}:\d{2}/

interface AuxResponseData {
  message: string
}

const parseData = (data: AuxResponseData) =>
  JSON.parse(JSON.stringify(data), (_key, value) =>
    typeof value === 'string' && DATE_REGEX.test(value) ? new Date(value) : value,
  )

export const fetchAux = async <T, P extends Record<string, string> = {}>(
  route: string,
  params?: P,
  init?: RequestInit,
): Promise<T> => {
  const response = await fetch(
    `${process.env.REACT_APP_AUX_API_URL}${route}${params ? '?' : ''}${new URLSearchParams(
      params,
    )}`,
    init,
  )

  if (!response.ok) throw new Error(response.statusText)

  const data: AuxResponseData = await response.json()

  return parseData(data)
}
