import React from 'react'

const NotFound: React.FC = () => {
  return (
    <>
      <p>404 | Page not found</p>
      <p>
        If you are trying to add your banking information, please double check you have followed the
        URL from your invitation.
      </p>
    </>
  )
}

export default NotFound
