import styled, { keyframes } from 'styled-components/macro'

const FADE_IN = keyframes`
from {
   opacity: 0;
   transform: translate(3rem, 0);
 }
 to {
   opacity: 1;
   transform: translate(0px, 0px);
 }
`

const FADE_OUT = keyframes`
from {
   opacity: 1;
   transform: translate(0px, 0px);
 }
 to {
   transform: translate(-3rem, 0);
   opacity: 0;
 }
`

const FADE_IN_REVERSE = keyframes`
from {
   opacity: 0;
   transform: translate(-3rem, 0);
 }
 to {
   opacity: 1;
   transform: translate(0px, 0px);
 }
`

const FADE_OUT_REVERSE = keyframes`
from {
   opacity: 1;
   transform: translate(0px, 0px);
 }
 to {
   transform: translate(3rem, 0);
   opacity: 0;
 }
`

const ANIMATIONS = {
  fadeIn: FADE_IN,
  fadeOut: FADE_OUT,
  fadeInReverse: FADE_IN_REVERSE,
  fadeOutReverse: FADE_OUT_REVERSE,
}

export const Slider = styled.div<{
  transitionStage: 'fadeIn' | 'fadeOut' | 'fadeInReverse' | 'fadeOutReverse'
}>`
  animation: 250ms ${({ transitionStage }) => ANIMATIONS[transitionStage]} forwards;
  flex: 1;
  display: flex;
  justify-content: center;
`
