import React, { useEffect } from 'react'
import { useQuery } from 'react-query'
import { useLocation, useNavigate, useParams } from 'react-router'
import { fetchPayeeDetailRequest, PayeeDetailRequestStatus } from '../api'

const STAGES: { [S in PayeeDetailRequestStatus]: string[] } = {
  [PayeeDetailRequestStatus.InviteSent]: ['', 'identity'],
  [PayeeDetailRequestStatus.OpenedByRecipient]: ['', 'identity'],
  [PayeeDetailRequestStatus.RecipientVerified]: ['account', 'confirm'],
  [PayeeDetailRequestStatus.RecipientVerificationFailed]: ['failed'],
  [PayeeDetailRequestStatus.RecipientDetailsProvided]: ['complete'],
  [PayeeDetailRequestStatus.AwaitingDocumentUpload]: ['awaiting-upload'],
}

interface Props {
  children: React.ReactNode
}

const AuthGuard: React.FC<Props> = ({ children }) => {
  const navigate = useNavigate()
  const location = useLocation()

  const { pdrId } = useParams<'pdrId'>()
  const { data: pdr, isError } = useQuery(pdrId, async () => await fetchPayeeDetailRequest(pdrId))

  const routes = location.pathname.split('/').slice(3) // everything after `/verify/[pdrId]`
  const step = routes[0] ?? ''

  useEffect(() => {
    const navigateTo =
      pdr?.status && !STAGES[pdr.status].includes(step) ? STAGES[pdr.status][0] : ''

    const isExpired = pdr?.expiresAt && new Date(pdr.expiresAt) < new Date()

    const showExpired = isExpired && navigateTo !== 'failed' && navigateTo !== 'complete'

    if (navigateTo || showExpired) navigate(showExpired ? 'expired' : navigateTo)
  }, [navigate, pdr?.status, pdr?.expiresAt, step, pdrId])

  useEffect(() => {
    if (isError) navigate('/')
  }, [isError, navigate])

  return <>{children}</>
}

export default AuthGuard
