import styled, { css, DefaultTheme, keyframes } from 'styled-components/macro'

export const ANIMATE_GRADIENT = keyframes`
  0% {
    background-position: 0 0;
  }
  100% {
    background-position: 100% 0;
  }
`

export const Wrapper = styled.div<{
  radius: keyof DefaultTheme['radius']
  padded: boolean
  border: boolean
}>`
  background-color: white;
  border-radius: ${({ theme, radius }) => theme.radius[radius]};
  ${({ onClick }) => onClick && 'cursor: pointer;'}
  padding: ${({ padded }) => (padded ? '4rem' : '0px')};
  ${({ theme }) => theme.screen.query(theme.screen.mobile)} {
    padding: ${({ padded }) => (padded ? '2rem' : '0px')};
  }

  height: fit-content;
  min-height: min(40rem, 80vh);

  ${({ border: outline, theme }) =>
    outline &&
    css`
      border: 0.1rem solid ${theme.color.black20};
    `}
`

export const Skeleton = styled.div`
  display: flex;
  flex-flow: column nowrap;
  gap: 1.4rem;
  padding: 2rem;
`

export const Box = styled.div`
  animation: ${ANIMATE_GRADIENT} 2s infinite linear;
  background-color: ${({ theme }) => theme.color.black20};
  background-image: linear-gradient(
    270deg,
    ${({ theme }) => `white, ${theme.color.black20}, white, ${theme.color.black20}`}
  );
  background-size: 300% 100%;
  border-radius: ${({ theme }) => theme.radius.small};
  height: 11rem;
  width: 100%;
`

export const Line = styled.div<{ long?: boolean }>`
  animation: ${ANIMATE_GRADIENT} 2s infinite linear;
  background-color: ${({ theme }) => theme.color.black20};
  background-image: linear-gradient(
    90deg,
    ${({ theme }) => `${theme.color.black20}, white, ${theme.color.black20}, white`}
  );
  background-size: 300% 100%;
  border-radius: ${({ theme }) => theme.radius.small};
  height: 1.2rem;
  width: ${({ long }) => (long ? '21rem' : '11rem')};
`
