import { fetchAux } from '../../common/api'
import { FileExtension } from '../types'
import {
  PayeeDetailRequestAccountType,
  PayeeDetailRequestResponse,
  SubmitBankingDetailsResponse,
} from './types'

type BankingInputRequestPayload = {
  routingNumber: string
  accountNumber: string
  bankName: string
  accountType: PayeeDetailRequestAccountType
  fileExtension?: FileExtension
  voidedCheckDocumentName: string
}

export const fetchPayeeDetailRequest = async (
  pdrId: string,
): Promise<PayeeDetailRequestResponse> => {
  return await fetchAux(`/payee-detail-request/${pdrId}`)
}

export const verifySelection = async (
  pdrId: string,
  optionId: string,
): Promise<PayeeDetailRequestResponse> => {
  return await fetchAux(`/payee-detail-request/${pdrId}/verify`, undefined, {
    method: 'POST',
    body: JSON.stringify({ optionId }),
    headers: {
      'Content-type': 'application/json',
    },
  })
}

export const completePayeeDetailRequest = async (
  pdrId: string,
  bankInfo: BankingInputRequestPayload,
): Promise<SubmitBankingDetailsResponse> => {
  return await fetchAux(`/payee-detail-request/${pdrId}/submit-payment-details`, undefined, {
    method: 'POST',
    body: JSON.stringify({ ...bankInfo }),
    headers: {
      'Content-type': 'application/json',
    },
  })
}

export * from './types'
