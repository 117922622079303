import { StateAbbreviation } from '../../common/types/address'

export enum PayeeDetailRequestStatus {
  InviteSent = 'INVITE_SENT',
  OpenedByRecipient = 'OPENED_BY_RECIPIENT',
  RecipientVerified = 'RECIPIENT_VERIFIED',
  RecipientVerificationFailed = 'RECIPIENT_VERIFICATION_FAILED',
  RecipientDetailsProvided = 'RECIPIENT_DETAILS_PROVIDED',
  AwaitingDocumentUpload = 'AWAITING_DOCUMENT_UPLOAD',
}

export interface PayeeDetailRequestResponse {
  id: string
  status: PayeeDetailRequestStatus
  expiresAt: Date
  recipientFirstName: string
  recipientEmail: string
  knownPartyFirstName: string
  knownPartyLastName?: string
  knownPartyAddress: string
  knownPartyCity: string
  knownPartyState: StateAbbreviation
  knownPartyZip: string
  verificationOptions: {
    id: string
    optionText: string
  }[]
}

export interface SubmitBankingDetailsResponse {
  blobServiceSASUrl: string
  blobName: string
  containerName: string
}

export enum PayeeDetailRequestAccountType {
  Checking = 'CHECKING',
  Savings = 'SAVINGS',
}

export interface PayeeDetailRequestBankInfo {
  routingNumber: string
  accountNumber: string
  bankName: string
  accountType: PayeeDetailRequestAccountType
}
