import { DefaultTheme } from 'styled-components/macro'
import { Bounds } from '../../types/styles'

const bounds = ({ min, max }: Bounds): string => {
  const b = []
  if (min) b.push(`min-width: ${min}px`)
  if (max) b.push(`max-width: ${max}px`)
  return `(${b.join(') and (')})`
}

const query = ({ min, max }: Bounds): string => {
  return `@media screen and ${bounds({ min, max })}`
}

const theme: DefaultTheme = {
  color: {
    altGreen: '#689553',
    black: '#29362c',
    black80: '#515b54',
    black30: '#aeb3ae',
    black20: '#dedfde',
    black10: '#f2f2f2',
    blue10: '#ecf2ff',
    blue50: '#3e7dfd',
    blueGrey: '#98aad2',
    blueGrey2: '#5e75a6',
    error: '#b4193e',
  },
  radius: {
    small: 'calc(1rem / 3)',
    medium: '0.75rem',
    large: '3rem',
  },
  font: {
    bold: 'metropolisbold',
    light: 'metropolislight',
    medium: 'metropolismedium',
    thin: 'metropolisthin',
  },
  screen: {
    mobile: { max: 767 },
    tablet: { min: 768, max: 1023 },
    desktop: { min: 1024 },
    bounds,
    query,
  },
}

export default theme
