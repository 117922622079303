import React, { useEffect, useState } from 'react'
import { useLocation } from 'react-router'
import RouteProvider from '../../providers/RouteProvider'
import { Slider } from './style'

interface Props {
  children: React.ReactNode[]
}

const Slides: React.FC<Props> = ({ children }) => {
  const location = useLocation()
  const [displayLocation, setDisplayLocation] = useState(location)
  const [transitionStage, setTransistionStage] = useState<
    'fadeIn' | 'fadeOut' | 'fadeInReverse' | 'fadeOutReverse'
  >('fadeIn')
  const onGoBack = () => setTransistionStage('fadeOutReverse')
  useEffect(() => {
    window.addEventListener('popstate', onGoBack)
    return () => window.removeEventListener('popstate', onGoBack)
  }, [])

  useEffect(() => {
    if (location !== displayLocation && transitionStage !== 'fadeOutReverse')
      setTransistionStage('fadeOut')
  }, [location, displayLocation, transitionStage])

  return (
    <Slider
      transitionStage={transitionStage}
      onAnimationEnd={() => {
        if (transitionStage === 'fadeOut') {
          setTransistionStage('fadeIn')
          setDisplayLocation(location)
        } else if (transitionStage === 'fadeOutReverse') {
          setTransistionStage('fadeInReverse')
          setDisplayLocation(location)
        }
      }}
    >
      <RouteProvider location={displayLocation}>{children}</RouteProvider>
    </Slider>
  )
}

export default Slides
