import styled from 'styled-components/macro'

export const Layout = styled.main`
  width: 100vw;
  height: 100%;

  overflow-y: auto;
  overflow-x: hidden;
  padding: 1rem;

  ${({ theme }) => theme.screen.query(theme.screen.mobile)} {
    padding: 0.5rem;
  }

  display: flex;
  justify-content: center;
`

export const Body = styled.div`
  ${({ theme }) => theme.screen.query({ min: theme.screen.tablet.min })} {
    max-width: 50rem;
  }

  display: flex;
  justify-content: stretch;
  flex-direction: column;
  align-items: stretch;
  gap: 1rem;
`

export const LegalList = styled.ul`
  list-style: none;
  padding-bottom: 1rem;
  padding-left: 0;
  margin-top: 0;

  color: ${({ theme }) => theme.color.black80};
  font-size: 12px;
  text-align: center;
`

export const LegalListItem = styled.li`
  display: inline-block;
  padding: 0 0.5rem;
  position: relative;

  &:not(:last-child) {
    border-right: 1px solid black;
  }

  ${({ theme }) => theme.screen.query(theme.screen.mobile)} {
    display: block;

    &:not(:last-child) {
      border-right: 0px;
    }
  }
`

export const LegalListItemLink = styled.a`
  color: ${({ theme }) => theme.color.black80};
  font-size: 12px;
`
