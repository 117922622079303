import React from 'react'
import { Route, Routes, RoutesProps } from 'react-router'
import NotFound from '../../pages/notFound'

const RouteProvider: React.FC<RoutesProps> = ({ children, ...props }) => {
  return (
    <Routes {...props}>
      {children}
      <Route path="*" element={<NotFound />} />
    </Routes>
  )
}

export default RouteProvider
