import React, { lazy, useState } from 'react'
import { Route } from 'react-router'
import Card from '../common/components/Card/Card'
import Slides from '../common/components/Slides'
import AuthGuard from './AuthGuard'
import { Body, Layout, LegalList, LegalListItem, LegalListItemLink } from './style'
import { BankInfoInput } from './types'

const LandlordInfo = lazy(() => import('./steps/LandlordInfo'))
const Identity = lazy(() => import('./steps/Identity'))
const Failed = lazy(() => import('./steps/Failed'))
const Account = lazy(() => import('./steps/Account'))
const Confirm = lazy(() => import('./steps/Confirm'))
const Complete = lazy(() => import('./steps/Complete'))
const AwaitingUpload = lazy(() => import('./steps/AwaitingUpload'))
const Expired = lazy(() => import('./steps/Expired'))

const Verify: React.FC = () => {
  const [bankInfo, setBankInfo] = useState<BankInfoInput>({})

  return (
    <Layout>
      <Body>
        <AuthGuard>
          <Slides>
            <Route
              path="/identity"
              element={
                <Card padded>
                  <Identity />
                </Card>
              }
            />
            <Route
              path="/failed"
              element={
                <Card padded>
                  <Failed />
                </Card>
              }
            />
            <Route
              path="expired"
              element={
                <Card padded>
                  <Expired />
                </Card>
              }
            />
            <Route
              path="/account"
              element={
                <Card padded>
                  <Account bankInfo={bankInfo} setBankInfo={setBankInfo} />
                </Card>
              }
            />
            <Route
              path="/confirm"
              element={
                <Card padded>
                  <Confirm bankInfo={bankInfo} />
                </Card>
              }
            />
            <Route
              path="/awaiting-upload"
              element={
                <Card padded>
                  <AwaitingUpload />
                </Card>
              }
            />
            <Route
              path="/complete"
              element={
                <Card padded>
                  <Complete />
                </Card>
              }
            />
            <Route
              path="/"
              element={
                <Card padded>
                  <LandlordInfo />
                </Card>
              }
            />
          </Slides>
        </AuthGuard>

        <LegalList>
          <LegalListItem>© 2022 Ag Resource Management</LegalListItem>
          <LegalListItem>
            <LegalListItemLink href="https://www.armlend.com/sitemap/">Sitemap</LegalListItemLink>
          </LegalListItem>
          <LegalListItem>
            <LegalListItemLink href="https://www.armlend.com/terms-of-service/">
              Terms of Service
            </LegalListItemLink>
          </LegalListItem>
          <LegalListItem>
            <LegalListItemLink href="https://www.armlend.com/privacy-policy/">
              Privacy Policy
            </LegalListItemLink>
          </LegalListItem>
        </LegalList>
      </Body>
    </Layout>
  )
}

export default Verify
