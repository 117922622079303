import React from 'react'
import ReactDOM from 'react-dom/client'
import { Route } from 'react-router'
import { BrowserRouter } from 'react-router-dom'
import RequestProvider from './common/providers/RequestProvider'
import RouteProvider from './common/providers/RouteProvider'
import StyleProvider from './common/providers/StyleProvider'
import './common/providers/StyleProvider/global.css'
import reportWebVitals from './reportWebVitals'
import Verify from './verify'

const ARMRedirect = () => {
  window.location.href = 'https://armlend.com'
  return null
}

const App: React.FC = () => {
  return (
    <StyleProvider>
      <RequestProvider>
        <RouteProvider>
          <Route path="/" element={<ARMRedirect />} />
          <Route path="/verify/:pdrId/*" element={<Verify />} />
        </RouteProvider>
      </RequestProvider>
    </StyleProvider>
  )
}

const root = ReactDOM.createRoot(document.getElementById('root')!)
root.render(
  <React.StrictMode>
    <BrowserRouter>
      <App />
    </BrowserRouter>
  </React.StrictMode>,
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals(console.log)
