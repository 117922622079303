import React from 'react'
import { DefaultTheme } from 'styled-components/macro'
import { Box, Line, Skeleton, Wrapper } from './style'

const SKELETON = (
  <Skeleton>
    <Line long />
    <Line />
    <Box />
  </Skeleton>
)

interface Props extends React.HTMLAttributes<HTMLDivElement> {
  skeleton?: boolean
  radius?: keyof DefaultTheme['radius']
  padded?: boolean
  border?: boolean
  children?: React.ReactNode
}

const Card: React.FC<Props> = ({
  children,
  className,
  skeleton = false,
  radius = 'medium',
  padded = false,
  border = false,
  ...divProps
}) => {
  return (
    <Wrapper radius={radius} padded={padded} border={border} {...divProps}>
      {skeleton ? SKELETON : <React.Suspense fallback={SKELETON}>{children}</React.Suspense>}
    </Wrapper>
  )
}

export default Card
