import { createGlobalStyle } from 'styled-components/macro'

export const Global = createGlobalStyle`
  html {
    color: ${({ theme }) => theme.color.black80};
    font-family: ${({ theme }) => theme.font.light};
    ${({ theme }) => theme.screen.query(theme.screen.mobile)} {
      font-size: 12px;
    }
  }
`
